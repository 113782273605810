import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { MODULE_INFOS_TOKEN } from '../injection-tokens';
import { ModuleInfo } from '../models';

export function provideModuleInfo(moduleName: string, info: Omit<ModuleInfo, 'name'>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: MODULE_INFOS_TOKEN,
      useValue: {
        ...info,
        name: moduleName,
      } as ModuleInfo,
      multi: true,
    },
  ]);
}
